import { useState, useCallback, useEffect } from 'react';

import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { memoize } from 'lodash';

import { getCurrentUserSession } from 'remote/auth';
import {
  completeNewPasswordChallenge as cognitoCompleteNewPasswordChallenge,
  verifyTokenForAssociation as cognitoVerifyTokenForAssociation,
  verifyTokenForSignIn as cognitoVerifyTokenForSignIn,
  signIn as cognitoSignIn,
  signOut as cognitoSignOut,
  getResetPasswordCode as cognitoGetResetPasswordCode,
  resetPassword as cognitoResetPassword,
} from 'remote/auth/cognito';
import { AuthFlags, isCognitoUserSession } from 'state/auth/types';

import {
  getUserPermissions,
  getUserLimits,
  Role,
} from '../../../services/graphql/src/lib/users/roles-and-permissions';
import { setDatadogRumUser } from '../../utils/datadog';

const decodeJWTPayload = (jwt: string) => {
  const payload = jwt.split('.')[1];

  try {
    return JSON.parse(atob(payload));
  } catch (err) {
    return {};
  }
};

const getIdFromJWT = memoize((jwt) => {
  const payload = decodeJWTPayload(jwt) || {};
  const cognitoId: string = payload.sub ?? '';
  const email: string = payload.email ?? '';
  const roles: string[] = payload['cognito:groups'] ?? [];

  return { cognitoId, email, roles };
});

const useAuth = () => {
  const [userSession, setSession] = useState<CognitoUserSession | AuthFlags | null>(null);
  const [needsReset, setNeedsReset] = useState(true);

  const getJWT = useCallback((session: CognitoUserSession | AuthFlags | null) => {
    if (!session) {
      return '';
    }
    if (isCognitoUserSession(session)) {
      return session.getIdToken().getJwtToken();
    }
    return '';
  }, []);

  const getIdentityFromJWT = useCallback(getIdFromJWT, [getIdFromJWT]);

  const signOut = useCallback(async () => {
    const signOutStatus = await cognitoSignOut();
    if (signOutStatus) {
      if (!needsReset) {
        setNeedsReset(true);
      }
    }
  }, [needsReset, setNeedsReset]);

  const getCurrentSession = useCallback(() => getCurrentUserSession(signOut), [signOut]);

  const resetSession = useCallback(async () => {
    const session = await getCurrentSession();

    const { email, cognitoId } = getIdentityFromJWT(getJWT(session));
    setDatadogRumUser({ email, id: cognitoId });

    setSession(session);
  }, [getCurrentSession, getIdentityFromJWT, getJWT]);

  const isAuthenticated = useCallback(() => {
    if (userSession) {
      if (
        'totpRequired' in userSession ||
        'shouldChangePassword' in userSession ||
        'totpSecret' in userSession
      ) {
        return false;
      }
      if (userSession.isValid()) {
        return true;
      }
    }
    return false;
  }, [userSession]);

  const changePassword = useCallback(({ newPassword }) => {
    return cognitoCompleteNewPasswordChallenge({ newPassword }).then((data) => data);
  }, []);

  const signIn = useCallback(
    async ({ email, password }: { email: string; password: string }) => {
      const lowerCaseEmail = email.toLowerCase();
      const session = await cognitoSignIn({ username: lowerCaseEmail, password });

      const { cognitoId } = getIdentityFromJWT(getJWT(session));
      setDatadogRumUser({ email: lowerCaseEmail, id: cognitoId });

      setSession(session);
      return session;
    },
    [getIdentityFromJWT, getJWT],
  );

  const verifyTOTPForAssociation = useCallback(
    ({ token }) =>
      cognitoVerifyTokenForAssociation(token).then((session) => {
        setSession(session);
        return session;
      }),
    [],
  );

  const verifyTOTPForSignIn = useCallback(({ token }) => {
    return cognitoVerifyTokenForSignIn(token).then((session) => {
      setSession(session);
      return session;
    });
  }, []);

  const getCtx = useCallback(() => {
    const jwt = getJWT(userSession);
    const { email, cognitoId, roles } = getIdentityFromJWT(jwt);
    const permissions = getUserPermissions(roles as Role[]);
    const limits = getUserLimits(roles as Role[]);
    return {
      // Computed values
      email,
      cognitoId,
      needsReset,
      userSession,
      roles,
      permissions,
      limits,

      // Functions
      changePassword,
      getCurrentSession,
      isAuthenticated,
      signIn,
      signOut,
      resetPassword: cognitoResetPassword,
      getResetPasswordCode: cognitoGetResetPasswordCode,
      verifyTOTPForAssociation,
      verifyTOTPForSignIn,
    };
  }, [
    changePassword,
    getCurrentSession,
    getIdentityFromJWT,
    getJWT,
    isAuthenticated,
    needsReset,
    signIn,
    signOut,
    userSession,
    verifyTOTPForAssociation,
    verifyTOTPForSignIn,
  ]);

  useEffect(() => {
    if (needsReset) {
      resetSession().then(() => setNeedsReset(false));
    }
  }, [needsReset, resetSession]);

  return {
    ...getCtx(),
  };
};

export default useAuth;
