import { datadogRum } from '@datadog/browser-rum';

const isLocalDev = process.env.NODE_ENV !== 'production';

export function initDatadogRum() {
  if (!process.env.REACT_APP_DD_RUM_APPLICATION_ID || !process.env.REACT_APP_DD_RUM_CLIENT_TOKEN) {
    console.warn('Datadog RUM is not initialized. Missing environment variables.');
    return;
  }

  datadogRum.init({
    applicationId: process.env.REACT_APP_DD_RUM_APPLICATION_ID,
    clientToken: process.env.REACT_APP_DD_RUM_CLIENT_TOKEN,
    site: process.env.REACT_APP_DD_RUM_SITE || 'datadoghq.eu',
    service: 'intl-support-tool',
    env: process.env.REACT_APP_RBI_ENV,
    // TODO: Parametrize and move to LD Flag
    sessionSampleRate: isLocalDev ? 0 : 100,
    sessionReplaySampleRate: isLocalDev ? 0 : 100,
    trackUserInteractions: true,
    trackLongTasks: true,
    trackResources: true,
    defaultPrivacyLevel: 'mask',
  });

  datadogRum.setGlobalContext({
    brand: process.env.REACT_APP_RBI_BRAND,
    stage: process.env.REACT_APP_RBI_ENV,
    awsRegion: process.env.REACT_APP_RBI_AWS_REGION,
  });
}

export function setDatadogRumUser(user: { id: string; email: string }) {
  datadogRum.setUserProperty('id', user.id);
  datadogRum.setUserProperty('email', user.email);
}
